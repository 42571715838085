var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: { title: _vm.$t("Archived project list") }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-sm",
                  attrs: {
                    type: "primary",
                    icon: "inbox",
                    disabled: !_vm.isRestoreEnable
                  },
                  on: {
                    click: function($event) {
                      _vm.confirmRestoreModal = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Restore")) + " ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("search-box", {
        staticClass: "pb-sm",
        attrs: { queryParam: _vm.queryParam },
        on: {
          changeTitle: _vm.changeTitle,
          changeSubDomain: _vm.changeSubDomain,
          handleSearch: _vm.handleSearch,
          handleReset: _vm.handleReset
        }
      }),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _c("SGrid", {
              ref: "sGrid",
              attrs: {
                rowData: _vm.projects,
                enableCheckbox: true,
                columnDefs: _vm.columnDefs,
                gridContext: _vm.context,
                "grid-name": "project-archive",
                pagination: _vm.pagination,
                rowHeight: 124
              },
              on: {
                onPageChange: _vm.handleChangePage,
                selectionChanged: _vm.handleSelectProject
              }
            })
          ],
          1
        )
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$tc("restoreConfirm", _vm.restoreProjects.length, {
              count: _vm.restoreProjects.length
            })
          },
          on: { ok: _vm.onClickRestore },
          model: {
            value: _vm.confirmRestoreModal,
            callback: function($$v) {
              _vm.confirmRestoreModal = $$v
            },
            expression: "confirmRestoreModal"
          }
        },
        [
          _c("SGrid", {
            ref: "sGrid",
            attrs: {
              "grid-height": 300,
              "grid-name": "project-restore-model",
              rowData: _vm.restoreProjects,
              enableCheckbox: false,
              columnDefs: _vm.columnRestoreDefs,
              gridContext: _vm.context
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }