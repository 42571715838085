//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    name() {
      return this.params.data.name;
    }
  }
});
