//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SearchBox from '@/views/project/components/SearchBox.vue';
import {
  NameCellRenderer,
  CreatedCellRenderer,
  ActionsCellRenderer,
  SubDomainCellRenderer,
  PhotoCellRenderer,
  DesCellRenderer
} from './grid';
import { PROJECT_STATUS } from '@/enum';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';

export default {
  components: {
    SearchBox,
    PageHeader,
    NameCellRenderer,
    CreatedCellRenderer,
    ActionsCellRenderer,
    SubDomainCellRenderer,
    PhotoCellRenderer,
    DesCellRenderer
  },
  data() {
    return {
      projects: [],
      queryParam: {
        status: 'all',
        title: '',
        sub_domain: ''
      },
      loading: false,
      context: { componentParent: this },
      pagination: { current: 1, pageSize: 20, total: 0 },
      restoreProjects: [],
      confirmRestoreModal: false
    };
  },
  created() {
    const { title, sub_domain } = this.$route.query;
    this.queryParam = {
      title: title || '',
      sub_domain: sub_domain || ''
    };
    this.pagination.pageSize = +this.$route.query.size || 20;
    this.pagination.current = +this.$route.query.page || 1;
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    columnDefs() {
      return [
        {
          headerName: 'project.grid.Photo',
          field: 'photo',
          width: 150,
          lockPosition: true,
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'project.grid.Name',
          field: 'name',
          lockPosition: true,
          cellRenderer: 'NameCellRenderer'
        },
        {
          headerName: 'project.grid.URL',
          field: 'sub_domain',
          lockPosition: true,
          cellRenderer: 'SubDomainCellRenderer'
        },
        {
          headerName: 'project.grid.Description',
          field: 'description',
          lockPosition: true,
          cellRenderer: 'DesCellRenderer'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          lockPosition: true,
          cellRenderer: 'CreatedCellRenderer'
        },
        {
          headerName: 'project.grid.Actions',
          field: 'actions',
          width: 120,
          lockPosition: true,
          cellRenderer: 'ActionsCellRenderer'
        }
      ];
    },
    columnRestoreDefs() {
      return [
        {
          headerName: 'project.grid.Name',
          field: 'name',
          resizable: true,
          suppressSizeToFit: true,
          lockPosition: true,
          cellClass: 'locked-col'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          resizable: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'CreatedCellRenderer'
        }
      ];
    },
    filter() {
      const { title, sub_domain } = this.queryParam;
      let where = {
        ...(title && { name: { ilike: `%${title}%` } }),
        ...(sub_domain && { sub_domain: { ilike: `%${sub_domain}%` } }),
        status: 'ARCHIVE'
      };
      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        order: 'modified DESC'
      };
      return filter;
    },
    isRestoreEnable() {
      return this.restoreProjects.length ? true : false;
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const companyId = this.userInfo.company_id;
        // const [projects, count] = await Promise.all([
        const projects = await this.$s.api.project.find(companyId, { filter: this.filter });
        const count = await this.$s.api.project.count(companyId, this.filter.where);
        // ]);
        this.projects = projects;
        this.pagination = { ...this.pagination, total: count?.count };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    handleReset() {
      this.queryParam = { title: '', sub_domain: '' };
      this.handleSearch();
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeSubDomain(value) {
      this.queryParam.sub_domain = value;
    },
    handleChangePage({ page, pageSize }) {
      this.pagination = { ...this.pagination, current: page, pageSize };
      this.getData();
    },
    async handleSelectProject(event) {
      let nodes = event.api.getSelectedNodes();
      this.restoreProjects = nodes.map(node => node.data);
    },
    async onClickRestore() {
      try {
        this.loading = true;
        let ids = this.restoreProjects.map(proj => proj.id);
        let data = {
          ids,
          status: PROJECT_STATUS.ACTIVE
        };
        await this.$s.api.project.archiveProjects(data);
        this.$message.success(`${this.$t('Restore successfully')}`);
        this.restoreProjects = [];
        this.getData();
      } catch (error) {
        this.$notification.error({ message: `${this.$t('Restore failed')}: ${error.message}` });
        console.log(error);
      } finally {
        this.confirmRestoreModal = false;
        this.loading = false;
      }
    }
  }
};
