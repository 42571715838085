var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "column" } },
    [
      _c(
        "div",
        { staticStyle: { "line-height": "1.8" } },
        [
          _c("router-link", { attrs: { to: _vm.pathToPages } }, [
            _vm._v(" " + _vm._s(_vm.$t("Page list")) + " ")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }