//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    data() {
      return this.params.data || {};
    },
    subDomain() {
      return this.data?.sub_domain;
    },
    subDomainUrl() {
      const baseSubDomain = process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
      return `https://${this.subDomain}${baseSubDomain}`;
    }
  }
});
