var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "description text-ellipsis",
      attrs: { href: _vm.subDomainUrl, target: "_blank" }
    },
    [_vm._v(" " + _vm._s(_vm.subDomainUrl) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }