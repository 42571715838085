//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    created() {
      return this.params.data.created;
    },
    pathToPages() {
      return `/archived-projects/${this.params.data.id}/pages`;
    }
  }
});
